import React from 'react';
import PropTypes from 'prop-types';

import ImageTag from '../../../../../shared/ImageTag/ImageTag';

import singapore from '../../../../../../assets/images/festivals/singapore-logo.svg';
import IMDA from '../../../../../../assets/images/festivals/IMDA-logo.png';
import informaTech from '../../../../../../assets/images/festivals/informa-tech-logo@2x.png';
import informaGlobal from '../../../../../../assets/images/informa-global.png';
import SECB from '../../../../../../assets/images/festivals/secb-logo.svg';

import './FestivalFooterSponsors.scss';

const sponsorsAndPartnersConfig = {
  SINGAPORE: {
    alt: 'Singapore Passion Made Possible',
    src: singapore,
  },
  IMDA: {
    alt: 'Infocomm media development authority',
    src: IMDA,
  },
  INFORMA_TECH: {
    alt: 'Informa Tech',
    src: informaTech,
  },
  INFORMA_GLOBAL: {
    alt: 'Informa',
    src: informaGlobal,
  },
  SECB: {
    alt: 'Singapore Exhibition & Convention Bureau',
    src: SECB,
  },
};

export default function FestivalFooterSponsors({ config }) {
  return (
    <div className="c-festival-footer-sponsors">
      {config.map(({ heading, listing = [] }, index) => (
        <div key={index} className="c-festival-footer-sponsors__category">
          {heading && (
            <div className="c-festival-footer-sponsors__heading">{heading}</div>
          )}
          <div className="c-festival-footer-sponsors__listing">
            {listing.map(({ logo, href, target }) => {
              const { alt, src } = sponsorsAndPartnersConfig[logo];

              return (
                <div key={logo} className="c-festival-footer-sponsors__logo">
                  <a href={href} target={target}>
                    <ImageTag alt={alt} src={src} imgix={false} lazy={false} />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

FestivalFooterSponsors.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      listing: PropTypes.arrayOf(
        PropTypes.shape({
          logo: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
          target: PropTypes.string,
        }),
      ),
    }),
  ),
};
